<template>
  <div>
    <ProfileDetails
      :user="user || cachedUser"
      :profileFields="profileFields"
      @complete="isValid = $event"
      @update="onUpdate"
      @dirty="hasUserChanges = true" />
    <StepperButtons
      :canComplete="isValid"
      :isRequired="true"
      :isSaving="isSaving"
      @continue="clickContinue" />
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { updateUser } from "@/services/api.service.js";
  const api = { updateUser };
  import StepperButtons from "@/components/common/stepper/StepperButtons.vue";
  import ProfileDetails from "@/components/ProfileDetails.vue";

  export default {
    name: "StepProfileDetails",
    components: {
      StepperButtons,
      ProfileDetails,
    },
    data() {
      return {
        cachedUser: {
          first: "",
          last: "",
          faq: [],
        },
        isValid: false,
        hasUserChanges: false,
        isSaving: false,
      };
    },
    computed: {
      ...mapState(["user", "profileFields"]),
    },
    methods: {
      onUpdate(val) {
        this.cachedUser = { ...this.user, ...val };
      },
      async clickContinue() {
        try {
          if (this.hasUserChanges) {
            this.isSaving = true;
            await api.updateUser(this.cachedUser);
            this.$store.dispatch("setUser", this.cachedUser);
          }
          this.$emit("complete");
        } catch (error) {
          this.$toast.error(this.$lang.editProfile_Error);
        } finally {
          this.isSaving = false;
        }
      },
    },
  };
</script>
