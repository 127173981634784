<template>
  <div class="step">
    <div class="step-buttons">
      <common-button
        class="step-button step-continue"
        @click="$emit('continue')"
        :disabled="!canComplete"
        :loading="isSaving"
        >{{ $lang.general_Continue }}</common-button
      >
      <common-button
        v-if="!isRequired"
        class="step-button"
        variant="text"
        @click="$emit('skip')"
        :disabled="isSaving"
        >{{ $lang.general_Skip }}
      </common-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "StepperButtons",
    props: {
      canComplete: {
        type: Boolean,
        default: true,
      },
      isRequired: {
        type: Boolean,
        default: false,
      },
      isSaving: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
<style lang="scss" scoped>
  .step {
    margin-top: 52px;
    border-radius: 2rem;
    background-color: $white;
  }

  .step-buttons {
    margin-top: 52px;
    display: inline-grid;

    .step-button {
      width: 164px;
    }

    .step-continue {
      background-color: var(--accent-color);
      color: $white;
    }
  }
</style>
